// body {
//   font-family: "Jost", sans-serif !important;
//   height: 100%
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

body {
  // font-family: "Inter", sans-serif;
  //font-family: "Jost", sans-serif;
  font-family: "Figtree", serif !important;
  background-color: #fff;
}



#sidebar {
  // width: 240px;
  // position: fixed;
  // top: 0;
  // left: 0;
  // height: 200vh;
  // z-index: 999;
  background: #1e293b;
  // width: 250px;
  // color: #fff;
  // transition: all 0.3s;
  // padding: 10px;
  // &.active {
  //   margin-left: -240px;
  // }
  // @media only screen and (max-width: 728px) {
  //   .sideNavbar_big{
  //     background-color: #1e293b;
  //     width: 100%;
  //     position: fixed;
  //   }
  // }
}

@media only screen and (max-width: 728px) {
  // .sideNavbar_big{
  //   background-color: #1e293b;
  //   width: 500px;
  //   position: absolute;
  // }
}

#content {
  width: calc(100% - 240px);
  min-height: 100vh;
  transition: all 0.3s;

  // position: absolute;
  // top: 0;
  // right: 0;
  &.active {
    width: 100%;
  }
}

@media (max-width: 768px) {
  #sidebar {

    // margin-left: -100px;
    &.active {
      margin-left: 0;
    }
  }

  #content {
    width: 100%;

    &.active {
      width: calc(100% - 100px);
    }
  }
}

// .content {
//   padding: 20px;
// }

.side_nav {
  a {
    display: block;
    color: #fff;
    font-size: 13px;
    text-decoration: none;
    text-decoration: none;
    line-height: 30px;
    // padding: 15px 0;
    border-left: solid 3px transparent;
    padding-left: 0px;

    &:hover {
      color: #78a4eb;
    }
  }

  .designation {
    color: #796e6c
  }

  .image-small {
    height: 73px;
    width: 73px;
    object-fit: cover;
    margin-top: 12px;
    border: 5px solid white;
    border-radius: 37px;
  }
}

// .admin-bg {
//  background-color: #fafafa;
// }

.candidate-interview-time {
  width: inherit !important;
}

.interview-model {
  border: 2px solid green;
  padding-top: 10px;
  padding-bottom: 10px;
}

.outlet_scroll {
  min-width: 100%;
  max-width: 100%;
  height: 90vh;
  overflow-y: auto;
  background: #f4f4f4;

  @media screen and (max-width:992px) {
    padding-left: 4rem !important;
  }
}

// .bg-white {
//   box-shadow: 2px 3px 3px #333 !important;
// }
// .outlet_scroll::-webkit-scrollbar {
//   display: none;
// }

.team_button {
  white-space: nowrap;
}

.modal_scroll {
  height: 300px;
  overflow-y: auto;
}

@media only screen and (max-width:729px) {
  .resp_status_big {
    display: none;
  }
}

@media only screen and (min-width:728px) {
  .resp_status_small {
    display: none;
  }
}

.table {
  // background-color: red;
  border-top-left-radius: 8px;
  overflow: hidden;
  border-top-right-radius: 8px;
  margin-top: 5px;
}

.table tr td:first-child,
.table tr th:first-child {
  border-left: solid 1px #eff4fa;
}

.table tr td:last-child,
.table tr th:last-child {
  border-right: solid 1px #eff4fa;
}

.table tbody tr:nth-child(odd) td {
  background: #fafafb;
}

.table tbody tr {
  border-width: 0;
}

.table tbody tr:hover td {
  background: #fafafb;
}

.table .btn.btn-secondary {
  background: #01579b;
  border-color: #01579b;
}

.pagination a {
  color: #1e293b;
  cursor: pointer;

}

.pagination a:hover {
  background: rgba(30, 41, 59, 0.1);
}

.pagination .active a {
  background-color: #1e293b;
  border-color: #1e293b;

}

.skills-style {
  width: 220px;
  white-space: break-spaces;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.css-1uvydh2 {
  padding-top: 9px;
  padding-bottom: 9px;
}

.MuiInputBase-input.MuiOutlinedInput-input.css-1x5jdmq {
  padding-top: 9px;
  padding-bottom: 9px;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.css-p0rm37 {
  margin-top: -6px;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-required.css-p0rm37 {
  margin-top: -6px;
}

;

.BackgroundClr {
  // background-color: #C6B0EF;
  background-color: #e5f3ff;
}

.empty-BackgroundClr {
  background-color: #e5f3ff;
  display: flex;
  justify-content: center;
  align-items: center;
}